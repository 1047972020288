import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import get from 'lodash/get';

import { FooterContainer, FooterContents } from '@components/footer/styles';
import { site } from '@data/static';

const getCopyright = (start: number, end: number) => {
  const startYear = new Date(start).getFullYear();
  const endYear = new Date(end).getFullYear();
  if (startYear === endYear) return startYear;
  return [startYear, endYear].join('-');
};

export const SiteFooter: React.FC = () => {
  const [now, setNow] = useState(Date.now());
  const data = useStaticQuery(graphql`
    query GetSiteFooterData {
      site {
        siteMetadata {
          createdAt(formatString: "YYYY")
        }
      }
    }
  `);

  const copyrightStartYear = get(data, 'site.siteMetadata.createdAt');

  useEffect(() => {
    setNow(Date.now());
  }, []);

  return (
    <FooterContainer>
      {/* <FooterTopWave className='footer-top-wave' /> */}
      <FooterContents>
        <div>
          <p>
            Personal website &amp; portfolio by Aditi Mohanty.
            <br />
            Built with{' '}
            <a href='https://www.gatsbyjs.org/' title='GatsbyJS' rel='nofollow' className='subtle underline'>
              GatsbyJS
            </a>{' '}
            &amp; hosted on{' '}
            <a href='https://vercel.com/' title='Vercel.com' rel='nofollow' className='subtle underline'>
              Vercel.com
            </a>
            .
          </p>
          <p>
            <small>© {getCopyright(copyrightStartYear, now)} Aditi Mohanty. All rights reserved.</small>
          </p>
        </div>
        <div>
          <p>
            <Link to='/'>aditimohanty.com</Link>
          </p>
          <ul>
            {site.footer.links.map((link) => (
              <li key={link.label}>
                <a href={link.href} title={link.title} className='subtle' rel={link.rel}>
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </FooterContents>
    </FooterContainer>
  );
};
