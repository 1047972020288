import styled from 'styled-components';
import { darken } from 'polished';

import { MaxWidthContainer } from '@components/container';

export const FooterContainer = styled.footer`
  background-color: ${(props) => props.theme.colors.footer};
  color: ${(props) => props.theme.colors.footerText};
  font-size: 0.8rem;
  /* margin-top: 100px; */
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;

  svg.footer-top-wave {
    position: absolute;
    width: 100%;
    bottom: 99%;
    bottom: calc(100% - 1px);
    left: 0;
    right: 0;
  }

  svg.footer-top-wave path {
    fill: ${(props) => props.theme.colors.footer};
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: 10px 18px;
    font-size: 0.9em;

    ${(props) => props.theme.mediaQueries.screenSmallOnly} {
      text-align: center;
    }
  }

  p,
  ul {
    margin-top: 0;
    margin-bottom: 1.1em;
  }

  small {
    opacity: 0.8;
  }

  .subtle {
    color: ${(props) => darken(0.2, props.theme.colors.muted)};
    text-decoration: underline;
  }
`;

export const FooterContents = styled(MaxWidthContainer)`
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 1 1 auto;
  }

  ${(props) => props.theme.mediaQueries.screenSmallOnly} {
    text-align: center;
  }

  ${(props) => props.theme.mediaQueries.screenMedium} {
    > div {
      flex: 0 1 auto;
    }
  }
`;
