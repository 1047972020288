import React from 'react';

import { GlobalStyle } from '@components/layout/primary/globalStyles';
import SiteThemeProvider from '@components/SiteThemeProvider';
import { setWindowCustomProperties } from '@utils/dom';
import { trackDelegated } from '@utils/analytics';
import { Navbar } from '@components/navigation';
import { SiteFooter } from '@components/footer';

export const PrimaryLayout: React.FC = (props) => {
  React.useEffect(setWindowCustomProperties);
  return (
    <div onClickCapture={trackDelegated}>
      <SiteThemeProvider>
        <GlobalStyle />
        <Navbar />
        {props.children}
        <SiteFooter />
      </SiteThemeProvider>
    </div>
  );
};
