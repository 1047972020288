import themes, { Theme } from '@components/layout/primary/theme';
import React, { useState, createContext, useMemo, useCallback, useEffect, useContext } from 'react';
import styled, { ThemeProvider, DefaultTheme } from 'styled-components';
import { Button } from 'rebass/styled-components';
import merge from 'lodash/fp/merge';

import { persistThemePreference, getThemePreferenceOnBoot } from './utils';

export const ToggleButton = styled(Button)`
  @media print {
    display: none;
  }
`;

const SiteThemeContext = createContext<{
  currentTheme: Theme;
  toggleTheme: () => void;
  toggleDebug: () => void;
}>({
  currentTheme: Theme.Light,
  toggleTheme: () => null,
  toggleDebug: () => null,
});

const oppositeTheme = {
  [Theme.Dark]: Theme.Light,
  [Theme.Light]: Theme.Dark,
};

const SiteThemeProvider: React.FC<{
  themeOverrides?: Partial<DefaultTheme>;
}> = ({ children, themeOverrides = {} }) => {
  const [themeKey, setThemeKey] = useState<Theme>(Theme.Light);
  const [debugGrid, setDebugGrid] = useState<boolean>(false);
  const themeValues = useMemo(
    () => merge(themes[themeKey], merge(themeOverrides, { flags: { debug: debugGrid } })),
    [themeKey, debugGrid, themeOverrides]
  );

  const toggleTheme = useCallback(() => {
    const nextTheme = oppositeTheme[themeKey];
    setThemeKey(nextTheme);
    setTimeout(() => {
      persistThemePreference(nextTheme);
    }, 0);
  }, [themeKey]);

  const toggleDebug = useCallback(() => {
    setDebugGrid((debug) => !debug);
  }, []);

  useEffect(() => {
    const preference = getThemePreferenceOnBoot();
    if (preference !== themeKey) {
      setThemeKey(preference);
    }
  }, []);

  return (
    <SiteThemeContext.Provider value={{ currentTheme: themeKey, toggleDebug, toggleTheme }}>
      <ThemeProvider theme={themeValues}>{children}</ThemeProvider>
    </SiteThemeContext.Provider>
  );
};

export const useSiteTheme = () => {
  const theme = useContext(SiteThemeContext);

  return theme;
};

export default SiteThemeProvider;
