import React, { ChangeEvent } from 'react';
import { FormElementProps } from '@commonTypes/form';
import isFunction from 'lodash/fp/isFunction';

import { Label, Knob } from './styles';

export interface SwitchProps extends FormElementProps<boolean> {
  elementBefore?: React.ReactNode;
  elementAfter?: React.ReactNode;
}

export const Switch: React.FC<SwitchProps> = (props: SwitchProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const { name, checked } = target;
    const value = checked === true;

    if (isFunction(props.onChange)) {
      props.onChange({ name, value }, e);
    }
  };

  return (
    <Label htmlFor={props.id} isChecked={props.value} {...props.track}>
      <input
        type='checkbox'
        id={props.id}
        name={props.name}
        onChange={handleChange}
        checked={props.value}
        tabIndex={0}
        {...props.track}
      />
      {props.elementBefore}
      <Knob isChecked={props.value} />
      {props.elementAfter}
    </Label>
  );
};

Switch.defaultProps = {
  elementBefore: null,
  elementAfter: null,
};
