import styled from 'styled-components';

export const MaxWidthContainer = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth.container[0]};
  margin: 0 auto;

  ${(props) => props.theme.mediaQueries.screenMedium} {
    width: 95%;
    width: calc(100% - 100px);
    max-width: ${(props) => props.theme.maxWidth.container[1]};
  }

  ${(props) => props.theme.mediaQueries.screenLarge} {
    max-width: ${(props) => props.theme.maxWidth.container[2]};
  }
`;
