import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const ThemeSwitchIcon = styled.svg<{ iconType?: 'on' | 'off' }>`
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  height: 14px;
  width: 14px;
  top: 5px;

  stroke: ${(props) => (props.iconType === 'on' ? 'white' : lighten(0.6, props.theme.colors.background))};
  ${(props) => props.iconType === 'on' && `left: 5px;`}
  ${(props) => props.iconType === 'off' && `right: 5px;`}
`;

export const Sun = (props: React.HtmlHTMLAttributes<SVGSVGElement>) => {
  return (
    <ThemeSwitchIcon
      viewBox='0 0 24 24'
      stroke='currentColor'
      strokeWidth='3'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      iconType='on'
      {...props}
    >
      <circle cx='12' cy='12' r='5'></circle>
      <line x1='12' y1='1' x2='12' y2='3'></line>
      <line x1='12' y1='21' x2='12' y2='23'></line>
      <line x1='4.22' y1='4.22' x2='5.64' y2='5.64'></line>
      <line x1='18.36' y1='18.36' x2='19.78' y2='19.78'></line>
      <line x1='1' y1='12' x2='3' y2='12'></line>
      <line x1='21' y1='12' x2='23' y2='12'></line>
      <line x1='4.22' y1='19.78' x2='5.64' y2='18.36'></line>
      <line x1='18.36' y1='5.64' x2='19.78' y2='4.22'></line>
    </ThemeSwitchIcon>
  );
};

export const Moon = (props: React.HtmlHTMLAttributes<SVGSVGElement>) => {
  return (
    <ThemeSwitchIcon
      viewBox='0 0 24 24'
      width='24'
      height='24'
      stroke='currentColor'
      strokeWidth='3'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      iconType='off'
      {...props}
    >
      <path d='M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z'></path>
    </ThemeSwitchIcon>
  );
};
