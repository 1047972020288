import React from 'react';

import { useSiteTheme } from '@components/SiteThemeProvider/Provider';
import ThemeSwitch from '@components/ThemeSwitch';
import { NavbarContainer } from '@components/navigation/styles';

export const Navbar = () => {
  const { toggleTheme, currentTheme } = useSiteTheme();
  return (
    <NavbarContainer as='nav'>
      <ThemeSwitch theme={currentTheme} toggle={toggleTheme} />
    </NavbarContainer>
  );
};
