import merge from 'lodash/fp/merge';

export enum Theme {
  Dark = 'dark',
  Light = 'light',
}
// use dark theme as base
const dark = {
  breakpoints: ['40em', '52em', '64em'],
  maxWidth: {
    container: ['80%', '960px', '1200px'],
  },
  colors: {
    background: '#12121a',
    card: '#0f1115',
    footer: '#12121a',
    gray: '#C4C4C4',
    heading: '#ffffff',
    muted: '#CACACA',
    text: '#FFFFFF',
    debug: '#FF7C81',
    highlight: '#FF7C81',
    primary: '#FF7C81',
    scrollbar: '#FF466B',
    footerText: '#CACACA',
  },
  flags: {
    dark: true,
    debug: false,
    light: false,
  },
  fonts: {
    body: `'Nunito', sans-serif`,
    heading: `'Nunito', sans-serif`,
    monospace: 'Menlo, monospace',
  },
  fontSizes: {
    root: `18px`,
    body: `1rem`,

    // https://www.gridlover.net/try; base 18px, line-height 1.6, scale 1.414
    h1: `${51 / 18}rem`,
    h2: `${36 / 18}rem`,
    h3: `${25 / 18}rem`,
    h4: `${18 / 18}rem`,
    h5: `${18 / 18}rem`,
  },
  fontWeights: {
    body: 400,
    bold: 600,
    heading: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.6,
  },
  mediaQueries: {
    screenSmallOnly: `@media screen and (max-width: 39.99em)`,
    screenMedium: `@media screen and (min-width: 40em)`,
    screenLarge: `@media screen and (min-width: 52em)`,
  },
  radii: {
    card: '8px',
  },
  shadows: {
    card: '0px 0px 16px rgba(0, 0, 0, 0.44)',
    switch: 'inset 0 0 20px rgba(0,0,0,0.2), inset 0 0 5px -2px rgba(0,0,0,0.4)',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  tooltip: {
    background: '#d9e0e8',
    text: '#0f1115',
  },
  section: {
    secondary: {
      background: '#080810',
      heading: '#B276FF',
      subtitle: '#C4C4C4',
    },
  },
  card: {
    project: {
      background: '#13131E',
      text: '#C4C4C4',
      shadow: 'inset 0px -4px 44px #0E0E17',
      radius: '21px',
      tag: '#FFFFFF',
      highlight: '#B276FF',
    },
  },
};

export const light = merge(dark, {
  colors: {
    background: '#FFFFFF',
    card: '#FFFFFF',
    heading: '#000000',
    highlight: '#FF7C81',
    muted: '#C4C4C4',
    primary: '#FF7C81',
    text: '#797979',
    footer: '#f7f7f8',
    footerText: '#797979',
  },
  flags: {
    dark: false,
    debug: false,
    light: true,
  },
  shadows: {
    card: '0px 0px 16px rgba(121, 121, 121, 0.2)',
  },
  tooltip: {
    background: '#0f1115',
    text: '#d9e0e8',
  },
  section: {
    secondary: {
      background: '#ebedef',
      heading: '#B276FF',
      subtitle: '#999999',
    },
  },
  card: {
    project: {
      background: '#FFFFFF',
      text: '#797979',
      shadow: '0px 0px 16px rgba(121, 121, 121, 0.2)',
      radius: '21px',
      tag: '#FF7C81',
      highlight: '#B276FF',
    },
  },
});

const themes = {
  [Theme.Light]: light,
  [Theme.Dark]: dark,
};

declare module 'styled-components' {
  type Theme = typeof light; // since light merges both

  export interface DefaultTheme extends Theme {
    flags: Theme['flags'];
  }
}

export default themes;
