import { createGlobalStyle } from 'styled-components';
import { darken, transparentize } from 'polished';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${(props) => props.theme.fontSizes.root};
  }

  body {
    font-size: ${(props) => props.theme.fontSizes.body};
    font-family: ${(props) => props.theme.fonts.body};
    font-weight: ${(props) => props.theme.fontWeights.body};
    margin: 0;
    color: ${(props) => props.theme.colors.text};
    line-height: ${(props) => props.theme.lineHeights.body};
    background-color: ${(props) => props.theme.colors.background};
    transition: background-color 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), color 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);

    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.colors.scrollbar};
      outline: 1px solid ${(props) => props.theme.colors.scrollbar};
      border-radius: 8px;
    }
  }

  * {
    box-sizing: border-box;

    ${(props) =>
      props.theme.flags.debug &&
      `
      background-color: ${transparentize(0.95, props.theme.colors.debug)};
      border: 1px dashed ${props.theme.colors.debug};
    `}
  }

  html {
    line-height: ${(props) => props.theme.lineHeights.body}; /* 1 */
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
    text-decoration: none;
    transition: color 0.15s ease-out;

    &:hover{
      color: ${(props) => darken(0.1, props.theme.colors.primary)}
    }
  }

  ::selection {
    color: white;
    background-color: ${(props) => props.theme.colors.primary};
  }



  .tippy-box[data-animation="fade"][data-state="hidden"] {
    opacity: 0;
  }

  [data-tippy-root] {
    max-width: calc(100vw - 10px);
  }

  .tippy-box {
    position: relative;
    background-color: ${(props) => props.theme.tooltip.background};
    color: ${(props) => props.theme.tooltip.text};
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    outline: 0;
    transition-property: transform, visibility, opacity;
  }

  .tippy-box[data-placement^="top"] > .tippy-arrow {
    bottom: 0;
  }

  .tippy-box[data-placement^="top"] > .tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top;
  }

  .tippy-box[data-placement^="bottom"] > .tippy-arrow {
    top: 0;
  }

  .tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom;
  }

  .tippy-box[data-placement^="left"] > .tippy-arrow {
    right: 0;
  }

  .tippy-box[data-placement^="left"] > .tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center left;
  }

  .tippy-box[data-placement^="right"] > .tippy-arrow {
    left: 0;
  }

  .tippy-box[data-placement^="right"] > .tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right;
  }

  .tippy-box[data-inertia][data-state="visible"] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }

  .tippy-arrow {
    width: 16px;
    height: 16px;
    color: ${(props) => props.theme.tooltip.background};
  }

  .tippy-arrow:before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }

  .tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1;
  }
`;
