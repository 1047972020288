import storage from '@utils/storage';
import { Theme } from '@components/layout/primary/theme';

export const getSystemThemePreference = () => {
  const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
  const isDark = matchMedia?.matches === true;
  return isDark ? Theme.Dark : Theme.Light;
};

export const getPersistedThemePreference = (): Theme | null => {
  const persistedTheme = storage.get('theme');
  if (persistedTheme === Theme.Light || persistedTheme === Theme.Dark) {
    return persistedTheme;
  }
  return null;
};

export const persistThemePreference = (currentTheme: Theme): void => {
  storage.set('theme', currentTheme);
};

export const getThemePreferenceOnBoot = (): Theme => {
  const persistedPreference = getPersistedThemePreference();

  if (persistedPreference !== null) {
    return persistedPreference;
  }

  return getSystemThemePreference();
};
