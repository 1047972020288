export enum ATTRIBUTE_PREFIX {
  track = 'data-ga',
  category = 'data-ga-category',
  action = 'data-ga-action',
  label = 'data-ga-label',
  value = 'data-ga-value',
}

export interface Event {
  category?: string;
  action: string;
  label?: string;
  value?: number;
}

export interface TrackingAttributes {
  [ATTRIBUTE_PREFIX.track]: boolean;
  [ATTRIBUTE_PREFIX.label]?: string;
  [ATTRIBUTE_PREFIX.value]?: number;
}

export const getCategoryForPage = () => {
  const { pathname } = window.location;
  if (pathname === '/') {
    return 'homepage';
  }
  return pathname.split('/')[1];
};

export const trackEvent = (originalEvent: Event, fieldsObject?: UniversalAnalytics.FieldsObject) => {
  const event = {
    eventCategory: originalEvent.category || getCategoryForPage(),
    eventAction: originalEvent.action,
    ...(typeof originalEvent.label !== 'undefined' && { eventLabel: originalEvent.label }),
    ...(typeof originalEvent.value !== 'undefined' && { eventValue: originalEvent.value }),
  };
  if (process.env.NODE_ENV === 'production' && window && typeof window.ga === 'function') {
    window.ga(
      'send',
      {
        hitType: 'event',
        ...event,
      },
      fieldsObject
    );
  } else {
    // eslint-disable-next-line no-console
    console.table(event);
  }
};

export const getSanitizedValue = (value: string | number | null) => {
  if (typeof value === 'number' && Number.isInteger(value)) {
    return { value };
  }
  const asNumber = parseInt(`${value}`, 10);
  return Number.isNaN(asNumber) ? {} : { value: asNumber };
};

export const getSanitizedLabel = (element: HTMLElement) => {
  const labelAttribute = element.getAttribute(ATTRIBUTE_PREFIX.label);
  return { label: labelAttribute || element.innerText };
};

export const trackDelegated = (event: React.MouseEvent<HTMLElement>) => {
  const target = event.target as HTMLElement;
  const { type } = event;

  if (target.getAttribute(ATTRIBUTE_PREFIX.track) === null) {
    return;
  }

  const value = target.getAttribute(ATTRIBUTE_PREFIX.value);

  trackEvent({
    action: type,
    ...getSanitizedLabel(target),
    ...getSanitizedValue(value),
  });
};

export const onClickExternalLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
  const element = e.target as HTMLAnchorElement;
  if (element.tagName === 'A') {
    trackEvent(
      {
        action: 'click',
        ...getSanitizedLabel(element),
      },
      {
        transport: 'beacon',
        dimension1: element.href,
      }
    );
  } else if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn('trackExternalLink() called on non-anchor element');
  }
};
