import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Label = styled.label<{ isChecked: boolean }>`
  display: inline-flex;
  width: 44px;
  height: 24px;
  border-radius: 15px;
  position: relative;
  align-items: center;
  z-index: 0;
  cursor: pointer;
  ${(props) => {
    const {
      theme: {
        flags: { dark },
        colors: { background, primary },
        shadows,
      },
      isChecked: on,
    } = props;
    if (on) {
      return `
        background-color: ${primary};
      `;
    }
    return `
    background-color ${dark ? lighten(0.15, background) : darken(0.15, background)};
    box-shadow: ${shadows.switch};
    `;
  }};

  /* &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
    outline-width: 1px;
  } */

  input {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  svg {
    pointer-events: none;
  }
`;

export const Knob = styled.span<{ isChecked: boolean }>`
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: transform 0.15s ease-in-out;
  transform: translateX(${({ isChecked: on }) => (on ? '22px' : '4px')});
  z-index: 2;
  pointer-events: none;
  ${(props) => {
    const {
      isChecked: on,
      theme: {
        colors,
        flags: { dark },
      },
    } = props;
    if (on) {
      return `background-color: white;`;
    }
    return `background-color: ${dark ? lighten(0.25, colors.background) : darken(0.45, colors.background)}`;
  }}
`;
