interface Storage {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  get: <T = string | number | Record<string, any>>(key: string) => T | null;
  set: <T = string | number | Record<string, any>>(key: string, value: T) => void;
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

/**
 * Check if localstorage is supported
 * Source: https://gist.github.com/MichalZalecki/70a831304b0e83afbc0fbad3e8577068
 */
function isSupported() {
  try {
    const testKey = '24a1e223-ad81-48cf-9dd3-eb3094a42284';

    window.localStorage.setItem(testKey, testKey);
    window.localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

const storageFactory = (namespace: string): Storage => {
  if (!isSupported()) {
    return {
      get: () => null,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      set: () => {},
    };
  }
  const getItemKey = (name: string) => `${namespace}.${name}.value`;

  const get: Storage['get'] = (item: string) => {
    const value = localStorage.getItem(getItemKey(item));
    if (value !== null) {
      return JSON.parse(value);
    }
    return value;
  };

  const set: Storage['set'] = (item, value) => {
    localStorage.setItem(getItemKey(item), JSON.stringify(value));
  };

  return {
    get,
    set,
  };
};

export default storageFactory('yaps');
