import debounce from 'lodash/fp/debounce';
/**
 * Set custom properties for vh
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export const setWindowCustomProperties = () => {
  const setViewportDimensions = () => {
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  };

  setViewportDimensions();
  const setViewportHeightOnResize = debounce(200, setViewportDimensions);

  window.addEventListener('resize', setViewportHeightOnResize);

  return () => {
    window.removeEventListener('resize', setViewportHeightOnResize);
  };
};
