import React from 'react';
import { Switch } from '@components/Switch/Switch';
import { Theme } from '@components/layout/primary/theme';
import { TrackingAttributes } from '@utils/analytics';

import { Sun, Moon } from './styles';

export interface ThemeSwitchProps {
  theme: Theme;
  toggle: () => void;
}

const trackingAttributes: TrackingAttributes = {
  'data-ga': true,
  'data-ga-label': 'switch-theme',
};

const ThemeValue = {
  [Theme.Light]: 1,
  [Theme.Dark]: 0,
};

const ThemeSwitch: React.FC<ThemeSwitchProps> = (props: ThemeSwitchProps) => {
  const { toggle, theme } = props;
  return (
    <Switch
      name='theme'
      id='toggle-theme'
      onChange={toggle}
      value={theme === Theme.Light}
      elementAfter={<Moon />}
      elementBefore={<Sun />}
      track={{ ...trackingAttributes, 'data-ga-value': ThemeValue[theme] }}
    />
  );
};

export default ThemeSwitch;
