import styled, { css } from 'styled-components';
import { MaxWidthContainer } from '@components/container';
import { Link as GatsbyLink } from 'gatsby';

export const NavbarContainer = styled(MaxWidthContainer)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16px;
`;

export const NavList = styled.ul`
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  list-style: none;

  li {
    padding: 10px 0;
    margin-right: 50px;
    display: inline-flex;
  }
`;

const navLinkCSS = css`
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.text};

  will-change: opacity;
  opacity: 0.4;
  transition: opacity 0.15s ease-out, color 0.15s ease-out;

  &:hover {
    opacity: 0.9;
  }
`;

export const Link = styled(GatsbyLink).attrs({
  activeClassName: 'active',
})`
  ${navLinkCSS}
`;

export const ExternalLink = styled.a`
  ${navLinkCSS}
`;
